import React from 'react';
import {Segment, Tab} from 'semantic-ui-react';

import FormsEobAddToList from '../../../forms/eob/add_to_list';
import FormsEobAddToPayment from '../../../forms/eob/add_to_payment';
import FormsEobAddToRemarks from '../../../forms/eob/add_to_remarks';

const ClaimEobAddControl = (props) => {
    const panes = [
        { 
            menuItem: { key: '1', icon: 'dolly flatbed', content: 'Add Benefit Item' }, 
            render: () => {
                return (
                    <Tab.Pane>
                        <FormsEobAddToList 
                            claimId={props.claimId}
                            benefitSchemeItems={props.benefitSchemeItems}
                            addEobItemLocally={props.addEobItemLocally}
                            remarkOptions={props.remarkOptions}
                            benefitLimitsAggregated={props.benefitLimitsAggregated}
                            adjEobItems={props.adjEobItems}
                            relatedEobItemsData={props.relatedEobItemsData}
                        />
                    </Tab.Pane>
                );
            }
        },
        { 
            menuItem: { key: '2', icon: 'dollar sign', content: 'Add Payment Item' }, 
            render: () =>  {
                return (
                    <Tab.Pane>
                        <FormsEobAddToPayment
                            claimId={props.claimId}
                            paymentOptions={props.paymentOptions}
                            addEobPaymentItemLocally={props.addEobPaymentItemLocally}
                            cpfHospitalListOptions={props.cpfHospitalListOptions}
                            adjPaymentItems={props.adjPaymentItems}
                            schoolPaymentOptions={props.schoolPaymentOptions}
                            getBankDetailsOfPaymentItem={props.getBankDetailsOfPaymentItem}
                            bankDetailsOfPaymentItem={props.bankDetailsOfPaymentItem}
                            validatePaymentItemBankAccount={props.validatePaymentItemBankAccount}
                            dataValidationPaymentItemBankAccount={props.dataValidationPaymentItemBankAccount}
                        />
                    </Tab.Pane>
                );
            }
        },
        { 
            menuItem: { key: '3', icon: 'dollar sign', content: 'Add Additional Remarks' }, 
            render: () =>  {
                return (
                    <Tab.Pane>
                        <FormsEobAddToRemarks
                            claimId={props.claimId}
                            adjAdditionalRemarks={props.adjAdditionalRemarks}
                            addEobRemarksLocally={props.addEobRemarksLocally}
                        />
                    </Tab.Pane>
                );
            }
        },
    ];
    return (
        <Segment>
            <Tab panes={panes} />
        </Segment>
    );
} 

export default ClaimEobAddControl;