import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import {Button, Dropdown, Grid, Header, Input, Message, Loader} from 'semantic-ui-react'
import _ from 'lodash';
import uuidv1 from 'uuid/v1';
import moment from 'moment';

import FormsInputsText from '../../inputs/text';
import FormsInputDropdown from '../../inputs/dropdown';
import FormsInputsDropdownSearch from '../../inputs/dropdownsearch';
import FormsInputDate from '../../inputs/date';
// import FormsInputsTextNormal from '../../inputs/text_normal';
// import FormsInputsDropdownAddition from '../../inputs/dropdownaddition';

const patientIdentificationSourceOptions = [
    { text: 'Local CPF Holder', value: 'C' },
    { text: 'Foreigner', value: 'F' }
];

const preparePaymentItemSelection = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.id };
    });
};

const prepareCPFHospitalListOptions = (list) => {
    return _.map(list, (item) => {
        return { text: item.description, value: item.code };
    });
};

const prepareSchoolPaymentOptions = (list) => {
    return _.map(list, (item, index) => {
        return { text: item.school_name, value: item.school_name, key: index };
    })
}

class FormsEobAddToPayment extends Component {
    renderAdditionalOtherInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description === 'OTHERS') {
                return (
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Field
                                name="otherPaymentName"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="Input payee name"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }
    }
    renderAdditionalSchoolInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description === 'SCHOOL') {
                const schoolPaymentSelection = prepareSchoolPaymentOptions(this.props.schoolPaymentOptions);
                return (
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Field
                                name="schoolPaymentName"
                                label=""
                                options={schoolPaymentSelection}
                                component={FormsInputsDropdownSearch}
                                secondaryLabel="Select School Name to Make Payment To"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }
    }
    renderAdditionalCPFInputs = (paymentItemId) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const {description} = selectedPaymentItem;
            if (description.indexOf('CPF-') !== -1) {
                const cpfHospitalListSelection = prepareCPFHospitalListOptions(this.props.cpfHospitalListOptions);
                return (
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <Field
                                name="patientIdentificationSource"
                                options={patientIdentificationSourceOptions}
                                label=""
                                component={FormsInputDropdown}
                                secondaryLabel="Patient Identification Source"
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="hrnNumber"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="HRN #"
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="admissionDate"
                                label=""
                                component={FormsInputDate}
                                secondaryLabel="Admission Date"
                                maxDate={moment()}
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Field
                                name="dischargeDate"
                                label=""
                                component={FormsInputDate}
                                secondaryLabel="Discharge Date"
                                maxDate={moment()}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Field
                                name="hospitalCode"
                                label=""
                                options={cpfHospitalListSelection}
                                component={FormsInputsDropdownSearch}
                                secondaryLabel="Hospital"
                            />
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Field
                                name="payerCPFNric"
                                label=""
                                component={FormsInputsText}
                                secondaryLabel="Payer CPF Nric"
                            />
                        </Grid.Column>
                    </Grid.Row>
                );
            }
        }

        return null;
    }

    renderAdditionalBankDetailsInformation = ({paymentItemId, paymentItemBankCode, paymentItemBankAccountNumber, paymentItemBankBranch}) => {
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const isInsurer = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "INSURER");
            const isHospital = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "HOSPITAL");
            const isGiroPaymentMode = !!(selectedPaymentItem.payment_mode && typeof selectedPaymentItem.payment_mode === "string" && selectedPaymentItem.payment_mode.trim().toUpperCase() === "GIRO");
            if ((isInsurer || isHospital) && isGiroPaymentMode) {
                const headerBankDetailsText = isHospital ? "Hospital's bank details" : "Insurer's bank details";
                const loadingBankDetailsText = isHospital ? "Loading hospital's bank details..." : "Loading insurer's bank details...";
                const loadingValidationBankAccountText = isHospital ? "Validating hospital's bank details..." : "Validating insurer's bank details...";
                return (
                    <Grid.Row>
                        {this.props.bankDetailsOfPaymentItem.loading ? (
                            <Grid.Column>
                                <div className="ui message">
                                    <div className="content">
                                        <Loader active size='small' className="centered" inline>{loadingBankDetailsText}</Loader>
                                    </div>
                                </div>
                            </Grid.Column>
                        ) : this.props.bankDetailsOfPaymentItem.error ? (
                            <Grid.Column>
                                <div className="ui negative message">
                                    <div className="header">
                                        {headerBankDetailsText}
                                    </div>
                                    <div className="content">
                                        <div className="ui divider"></div>

                                        <p>Failed to get bank details</p>
                                    </div>
                                </div>
                            </Grid.Column>
                        ) : this.props.dataValidationPaymentItemBankAccount.loading ? (
                            <Grid.Column>
                                <div className="ui message">
                                    <div className="content">
                                        <Loader active size='small' className="centered" inline>{loadingValidationBankAccountText}</Loader>
                                    </div>
                                </div>
                            </Grid.Column>
                        ) : (
                            <Grid.Column>
                                <div>
                                    <div className={!this.props.dataValidationPaymentItemBankAccount.isValid ? "ui negative message" : "ui positive message"}>
                                        <div className="header">
                                            {headerBankDetailsText}
                                        </div>
                                        <div className="content">
                                            <div className="ui divider"></div>

                                            <Grid columns={3}>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <div className="form-input-group">
                                                            <label>Bank code</label>
                                                        </div>
                                                        <Input fluid value={paymentItemBankCode} disabled={true}/>
                                                    </Grid.Column>

                                                    <Grid.Column>
                                                        <div className="form-input-group">
                                                            <label>Bank account number</label>
                                                        </div>
                                                        <Input fluid value={paymentItemBankAccountNumber} disabled={true}/>
                                                    </Grid.Column>

                                                    <Grid.Column>
                                                        <div className="form-input-group">
                                                            <label>Bank branch</label>
                                                        </div>
                                                        <Input fluid value={paymentItemBankBranch} disabled={true}/>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>

                                            {!this.props.dataValidationPaymentItemBankAccount.isValid && (
                                                <>
                                                    <div className="ui divider"></div>

                                                    <p>Invalid bank details</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                );
            }
        }

        return null;
    }

    additionalConditionDisableButton = ({paymentItemId, paymentItemBankCode, paymentItemBankAccountNumber}) => {
        let isDisabled = false;
        if (paymentItemId) {
            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === paymentItemId);
            const isGiroPaymentMode = !!(selectedPaymentItem.payment_mode && typeof selectedPaymentItem.payment_mode === "string" && selectedPaymentItem.payment_mode.trim().toUpperCase() === "GIRO");
            const isInsurer = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "INSURER");
            const isHospital = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "HOSPITAL");
            if ((isHospital || isInsurer) && isGiroPaymentMode) {
                if (
                    !paymentItemBankCode ||
                    !paymentItemBankAccountNumber ||
                    !this.props.bankDetailsOfPaymentItem ||
                    (this.props.bankDetailsOfPaymentItem && (this.props.bankDetailsOfPaymentItem.loading || this.props.bankDetailsOfPaymentItem.error)) ||
                    !this.props.dataValidationPaymentItemBankAccount ||
                    (this.props.dataValidationPaymentItemBankAccount && (this.props.dataValidationPaymentItemBankAccount.loading || !this.props.dataValidationPaymentItemBankAccount.isValid))
                ) {
                    isDisabled = true;
                }
            }
        }

        return isDisabled;
    }

    render() {
        const paymentOptions = preparePaymentItemSelection(this.props.paymentOptions);
        const findPreviousCPFPaymentItem = _.find(this.props.adjPaymentItems, (adjPaymentItem) => adjPaymentItem.schemeData && adjPaymentItem.schemeData.description.indexOf('CPF-') !== -1) ;
        const hasPreviousCPFPaymentItem = (findPreviousCPFPaymentItem) ? true : false;

        return (
            <Formik
                initialValues={{
                    paymentItemId: '',
                    remarks: '',
                    amount: 0,
                    patientIdentificationSource: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.patientIdentificationSource : 'C',
                    hrnNumber: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.hrnNumber : '',
                    admissionDate: (hasPreviousCPFPaymentItem) ? moment(findPreviousCPFPaymentItem.admissionDate) : moment(),
                    dischargeDate: (hasPreviousCPFPaymentItem) ? moment(findPreviousCPFPaymentItem.dischargeDate) : moment(),
                    hospitalCode: (hasPreviousCPFPaymentItem) ? findPreviousCPFPaymentItem.hospitalCode : '',
                    payerCPFNric: '',
                    schoolPaymentName: '',
                    otherPaymentName: '',
                    paymentItemBankCode: "",
                    paymentItemBankAccountNumber: "",
                    paymentItemBankBranch: "",
                }}
                onSubmit={(values, actions) => {
                    console.log(values,actions);
                    values.uuid = uuidv1();
                    values.schemeData = _.find(this.props.paymentOptions, (option) => option.id === values.paymentItemId);
                    this.props.addEobPaymentItemLocally(values, this.props.claimId);
                    actions.setSubmitting(false);
                    actions.resetForm();
                }}
                // validationSchema={LoginSchema}
                render={(props) => (
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={5}>
                                    {/*<Field*/}
                                    {/*    name="paymentItemId"*/}
                                    {/*    options={paymentOptions}*/}
                                    {/*    label=""*/}
                                    {/*    secondaryLabel="Payment Item"*/}
                                    {/*    component={FormsInputDropdown}*/}
                                    {/*/>*/}
                                    <Field name="paymentItemId">
                                        {({
                                              field,
                                              form,
                                              meta,
                                          }) => (
                                            <div className="form-input-group">
                                                <div>
                                                    <Header.Subheader>Payment Item</Header.Subheader>
                                                </div>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    options={paymentOptions}
                                                    onChange={async (e, ocProps) => {
                                                        await form.setFieldValue("paymentItemId", ocProps.value); // paymentItemId

                                                        if (ocProps.value) {
                                                            const selectedPaymentItem = _.find(this.props.paymentOptions, (paymentOption) => paymentOption.id === ocProps.value);
                                                            const isHospital = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "HOSPITAL");
                                                            const isInsurer = !!(selectedPaymentItem.type && typeof selectedPaymentItem.type === "string" && selectedPaymentItem.type.trim().toUpperCase() === "INSURER");
                                                            const isGiroPaymentMode = !!(selectedPaymentItem.payment_mode && typeof selectedPaymentItem.payment_mode === "string" && selectedPaymentItem.payment_mode.trim().toUpperCase() === "GIRO");
                                                            if ((isHospital || isInsurer) && isGiroPaymentMode) {
                                                                await this.props.getBankDetailsOfPaymentItem({
                                                                    code : isHospital ? selectedPaymentItem.hospital_code : selectedPaymentItem.insurer_code,
                                                                    type: selectedPaymentItem.type
                                                                });
                                                                if (!this.props.bankDetailsOfPaymentItem.loading) {
                                                                    if (!this.props.bankDetailsOfPaymentItem.error) {
                                                                        let paymentItemBankCode = (
                                                                            this.props.bankDetailsOfPaymentItem.data &&
                                                                            this.props.bankDetailsOfPaymentItem.data.bank_code !== undefined &&
                                                                            typeof this.props.bankDetailsOfPaymentItem.data.bank_code === "string"
                                                                        ) ? this.props.bankDetailsOfPaymentItem.data.bank_code.trim() : "";

                                                                        let paymentItemBankAccountNumber = (
                                                                            this.props.bankDetailsOfPaymentItem.data &&
                                                                            this.props.bankDetailsOfPaymentItem.data.bank_account_number !== undefined &&
                                                                            typeof this.props.bankDetailsOfPaymentItem.data.bank_account_number === "string"
                                                                        ) ? this.props.bankDetailsOfPaymentItem.data.bank_account_number.trim() : "";

                                                                        let paymentItemBankBranch = (
                                                                            this.props.bankDetailsOfPaymentItem.data &&
                                                                            this.props.bankDetailsOfPaymentItem.data.bank_branch !== undefined &&
                                                                            typeof this.props.bankDetailsOfPaymentItem.data.bank_branch === "string"
                                                                        ) ? this.props.bankDetailsOfPaymentItem.data.bank_branch.trim() : "";

                                                                        await this.props.validatePaymentItemBankAccount({
                                                                            bankCode: paymentItemBankCode,
                                                                            accountNumber: paymentItemBankAccountNumber,
                                                                            branchCode: paymentItemBankBranch
                                                                        });

                                                                        if (!this.props.dataValidationPaymentItemBankAccount.loading && this.props.dataValidationPaymentItemBankAccount.isValid) {
                                                                            const responseValidationBankAccountNumber = (
                                                                                this.props.dataValidationPaymentItemBankAccount.data &&
                                                                                this.props.dataValidationPaymentItemBankAccount.data.bank_account_number !== undefined &&
                                                                                typeof this.props.dataValidationPaymentItemBankAccount.data.bank_account_number === "string"
                                                                            ) ? this.props.dataValidationPaymentItemBankAccount.data.bank_account_number.trim() : "";

                                                                            const responseValidationBankBranch = (
                                                                                this.props.dataValidationPaymentItemBankAccount.data &&
                                                                                this.props.dataValidationPaymentItemBankAccount.data.bank_branch !== undefined &&
                                                                                typeof this.props.dataValidationPaymentItemBankAccount.data.bank_branch === "string"
                                                                            ) ? this.props.dataValidationPaymentItemBankAccount.data.bank_branch.trim() : "";


                                                                            if (responseValidationBankAccountNumber && responseValidationBankAccountNumber !== paymentItemBankAccountNumber) {
                                                                                paymentItemBankAccountNumber = responseValidationBankAccountNumber;
                                                                                if (responseValidationBankBranch) {
                                                                                    paymentItemBankBranch = responseValidationBankBranch;
                                                                                }
                                                                            }
                                                                        }

                                                                        await form.setFieldValue("paymentItemBankCode", paymentItemBankCode);
                                                                        await form.setFieldValue("paymentItemBankAccountNumber", paymentItemBankAccountNumber);
                                                                        await form.setFieldValue("paymentItemBankBranch", paymentItemBankBranch);
                                                                    } else {
                                                                        await form.setFieldValue("paymentItemBankCode", "");
                                                                        await form.setFieldValue("paymentItemBankAccountNumber", "");
                                                                        await form.setFieldValue("paymentItemBankBranch", "");
                                                                    }
                                                                }
                                                            } else {
                                                                await form.setFieldValue("paymentItemBankCode", "");
                                                                await form.setFieldValue("paymentItemBankAccountNumber", "");
                                                                await form.setFieldValue("paymentItemBankBranch", "");
                                                            }
                                                        } else {
                                                            await form.setFieldValue("paymentItemBankCode", "");
                                                            await form.setFieldValue("paymentItemBankAccountNumber", "");
                                                            await form.setFieldValue("paymentItemBankBranch", "");
                                                        }
                                                    }}
                                                    value={field.value}
                                                />
                                                {
                                                    (form.errors[field.name]) &&
                                                    (
                                                        <Message negative>
                                                            {form.errors[field.name]}
                                                        </Message>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </Field>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Field
                                        name="amount"
                                        label=""
                                        component={FormsInputsText}
                                        secondaryLabel="Payment Amount"
                                    />
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Field
                                        name="remarks"
                                        label=""
                                        component={FormsInputsText}
                                        secondaryLabel="Remarks"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            { this.renderAdditionalCPFInputs(props.values.paymentItemId) }
                            { this.renderAdditionalSchoolInputs(props.values.paymentItemId) }
                            { this.renderAdditionalOtherInputs(props.values.paymentItemId) }
                            { this.renderAdditionalBankDetailsInformation(props.values) }
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Button
                                        // disabled={!/^-?\d+\.?\d*$/.test(props.values.amount)}
                                        disabled={(
                                                !/^-?\d+\.?\d*$/.test(props.values.amount) ||
                                                this.additionalConditionDisableButton.bind(this, props.values)()
                                            )}
                                        type="submit" primary fluid loading={props.isSubmitting}
                                    >
                                        Add Payment Item
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

export default FormsEobAddToPayment;