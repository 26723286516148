import _ from "lodash";
import {
   GET_ADJ_CLAIMS_SUCCESS,
   GET_ADJ_CLAIMS_ZONE_SUMMARY_SUCCESS,
   // GET_ADJ_CLAIMS_ERROR,
   GET_ADJ_CASE_SUCCESS,
   // GET_ADJ_CASE_ERROR,
   GET_ADJ_CLAIM_SUCCESS,
   // GET_ADJ_CLAIM_ERROR,
   GET_CFG_BENEFIT_ITEMS_SUCCESS,
   // GET_CFG_BENEFIT_ITEMS_ERROR,
   ADD_EOB_ITEM_LOCALLY,
   REMOVE_EOB_ITEM_LOCALLY,
   SET_NEW_ADJ_CLAIM_STATUS,
   ADD_EOB_PAYMENT_ITEM_LOCALLY,
   REMOVE_EOB_PAYMENT_ITEM_LOCALLY,
   RESET_LOCAL_ADJ_DATA,
   ADD_EOB_REMARKS_LOCALLY,
   RESET_ADJUDICATION_LIST,
   SUBMIT_ADJ_RESULTS_ERROR,
   UPDATE_STATE_ADJ_CLAIM_SUPPORT_FILE_URL,
   UPDATE_STATE_ADJ_CLAIM_RECEIPT_FILE_URL,
   GET_HOSPITAL_BANK_DETAILS_SUCCESS,
   GET_HOSPITAL_BANK_DETAILS_FAILED,
   GET_ALL_INSURER_BANK_DETAILS_SUCCESS,
   GET_ALL_INSURER_BANK_DETAILS_FAILED,
   SET_STATE_BANK_DETAILS_OF_PAYMENT_ITEM,
   VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_LOADING,
   VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_SUCCESS,
   VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED
} from "../actions";
import { bindActionCreators } from "redux";

// const initialState = {
//     claims: [],
//     adjClaim: null,
//     adjCase: null,
//     adjEobItems: [],
//     adjEobIncurredAmount: 0,
//     adjEobPaymentAmount: 0,
//     adjEobPayableAmount: 0,
//     adjPaymentItems: [],
//     adjAdditionalRemarks: 'None',
//     adjNewStatus: 0,
//     error: false,
//     errorObject: null
// };

const initialState = {
   claims: [],
   totalClaim: [],
   claimsZoneSummary: [],
   adjClaim: {},
   adjCase: {},
   adjClaimsData: {},
   error: false,
   errorObject: null,
   hospitalBankDetails: {
      loading: false,
      data: null,
      error: null
   },
   allInsurerBankDetails: {
      loading: false,
      list: [],
      error: null
   },
   bankDetailsOfPaymentItem: {
      loading: false,
      data: null,
      error: null
   },
   dataValidationPaymentItemBankAccount: {
      loading: false,
      isValid: false,
      error: null
   },
};

const adjClaimData = {
   adjEobItems: [],
   adjEobIncurredAmount: 0,
   adjEobPaymentAmount: 0,
   adjEobPayableAmount: 0,
   adjPaymentItems: [],
   adjAdditionalRemarks: "",
   adjNewStatus: 0
};

export default function(state = initialState, action) {
   switch (action.type) {
      case RESET_LOCAL_ADJ_DATA:
         const updatedAdjClaimsData = Object.assign({}, state.adjClaimsData);
         delete updatedAdjClaimsData[action.payload];
         return Object.assign({}, state, {
            // adjClaim: null,
            // adjCase: null,
            adjClaimsData: updatedAdjClaimsData,
            error: false,
            errorObject: null
         });
      case ADD_EOB_REMARKS_LOCALLY:
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjAdditionalRemarks: action.payload.remarks
               })
            })
         });
      case GET_ADJ_CLAIMS_SUCCESS:
         return Object.assign({}, state, { claims: action.payload.data.claims, totalClaim: action.payload.data.totalClaim });
      case GET_ADJ_CLAIMS_ZONE_SUMMARY_SUCCESS:
         return Object.assign({}, state, { claimsZoneSummary: action.payload.data.claims });
      case GET_ADJ_CASE_SUCCESS:
         // return Object.assign({}, state, {adjCase: action.payload.data.case});
         return Object.assign({}, state, {
            adjCase: Object.assign({}, state.adjCase, { [action.payload.data.case.case_id]: action.payload.data.case })
         });
      case GET_ADJ_CLAIM_SUCCESS:
         const newAdjClaimData = { [action.payload.data.claim.claim_id]: adjClaimData };
         return Object.assign(
            {},
            state,
            {
               adjClaim: Object.assign({}, state.adjClaim, {
                  [action.payload.data.claim.claim_id]: action.payload.data.claim
               })
            },
            { adjClaimsData: Object.assign({}, state.adjClaimsData, newAdjClaimData) }
         );
      case ADD_EOB_ITEM_LOCALLY:
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjEobItems: [...state.adjClaimsData[action.payload.claimId].adjEobItems, action.payload.item],
                  adjEobPayableAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobPayableAmount +
                     action.payload.item.totalPayableAmount
                  ).toFixed(2),
                  adjEobIncurredAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobIncurredAmount +
                     action.payload.item.totalIncurredAmount
                  ).toFixed(2)
               })
            })
         });
      case REMOVE_EOB_ITEM_LOCALLY:
         let newAdjEobItems = [...state.adjClaimsData[action.payload.claimId].adjEobItems];
         let amountToDeduct = 0;
         let incurredAmtToDeduct = 0;
         newAdjEobItems = _.remove(newAdjEobItems, item => {
            if (item.uuid === action.payload.itemUuid) {
               amountToDeduct = item.totalPayableAmount;
               incurredAmtToDeduct = item.totalIncurredAmount;
            } else {
               return item;
            }
         });
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjEobItems: newAdjEobItems,
                  adjEobPayableAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobPayableAmount - amountToDeduct
                  ).toFixed(2),
                  adjEobIncurredAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobIncurredAmount - incurredAmtToDeduct
                  ).toFixed(2)
               })
            })
         });
      case SET_NEW_ADJ_CLAIM_STATUS:
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjNewStatus: action.payload.status
               })
            })
         , error: false, errorObject: null});
      //{adjClaimsData: { [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {adjNewStatus: action.payload.status})}}
      case ADD_EOB_PAYMENT_ITEM_LOCALLY:
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjPaymentItems: [
                     ...state.adjClaimsData[action.payload.claimId].adjPaymentItems,
                     action.payload.item
                  ],
                  adjEobPaymentAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobPaymentAmount +
                     parseFloat(action.payload.item.amount)
                  ).toFixed(2)
               })
            })
         });
      case REMOVE_EOB_PAYMENT_ITEM_LOCALLY:
         let newAdjPaymentItems = [...state.adjClaimsData[action.payload.claimId].adjPaymentItems];
         let paymentAmountToDeduct = 0;
         newAdjPaymentItems = _.remove(newAdjPaymentItems, item => {
            if (item.uuid === action.payload.itemUuid) {
               paymentAmountToDeduct = item.amount;
            } else {
               return item;
            }
         });
         return Object.assign({}, state, {
            adjClaimsData: Object.assign({}, state.adjClaimsData, {
               [action.payload.claimId]: Object.assign({}, state.adjClaimsData[action.payload.claimId], {
                  adjPaymentItems: newAdjPaymentItems,
                  adjEobPaymentAmount: +(
                     state.adjClaimsData[action.payload.claimId].adjEobPaymentAmount - paymentAmountToDeduct
                  ).toFixed(2)
               })
            })
         });
      case RESET_ADJUDICATION_LIST:
         return Object.assign({}, state, { claims: [], totalClaim: null, error: false, errorObject: null });
      case SUBMIT_ADJ_RESULTS_ERROR:
         return Object.assign({}, state, { error: true, errorObject: action.payload });
      case UPDATE_STATE_ADJ_CLAIM_RECEIPT_FILE_URL:
         let receipts = [...state.adjClaim[action.payload.claimId].receipts];
         let receipt = receipts[action.payload.idx];
         receipt.loadingFileUrl = action.payload.loadingFileUrl;
         receipt.fileUrl = action.payload.fileUrl;
         return Object.assign({}, state, {
            adjClaim: Object.assign({}, state.adjClaim, {
               [state.adjClaim[action.payload.claimId].receipts]: receipts
            })
         });
      case UPDATE_STATE_ADJ_CLAIM_SUPPORT_FILE_URL:
         let supportDocuments = [...state.adjClaim[action.payload.claimId].supportDocuments];
         let supportDocument = supportDocuments[action.payload.idx];
         supportDocument.loadingFileUrl = action.payload.loadingFileUrl;
         supportDocument.fileUrl = action.payload.fileUrl;
         return Object.assign({}, state, {
            adjClaim: Object.assign({}, state.adjClaim, {
               [state.adjClaim[action.payload.claimId].supportDocuments]: supportDocuments
            })
         });
      case GET_HOSPITAL_BANK_DETAILS_SUCCESS:
         return Object.assign({}, state, {
            hospitalBankDetails: {
               loading: false,
               data: action.payload,
               error: null
            }
         });
      case GET_HOSPITAL_BANK_DETAILS_FAILED:
         return Object.assign({}, state, {
            hospitalBankDetails: {
               loading: false,
               data: null,
               error: action.error
            }
         });
      case GET_ALL_INSURER_BANK_DETAILS_SUCCESS:
         return Object.assign({}, state, {
            allInsurerBankDetails: {
               loading: false,
               list: action.payload,
               error: null
            }
         });
      case GET_ALL_INSURER_BANK_DETAILS_FAILED:
         return Object.assign({}, state, {
            allInsurerBankDetails: {
               loading: false,
               list: [],
               error: action.error
            }
         });
      case SET_STATE_BANK_DETAILS_OF_PAYMENT_ITEM:
         return Object.assign({}, state, {
            bankDetailsOfPaymentItem: {
               loading: action.loading,
               data: action.payload,
               error: action.error
            }
         });
      case VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_LOADING:
         return Object.assign({}, state, {
            dataValidationPaymentItemBankAccount: {
               loading: true,
               isValid: false,
               message: "",
               data: null
            }
         });
      case VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_SUCCESS:
      case VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED:
         return Object.assign({}, state, {
            dataValidationPaymentItemBankAccount: {
               loading: false,
               isValid: action.isValid,
               message: action.message,
               data: action.payload
            }
         });
      default:
         return state;
   }
}
