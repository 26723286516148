import React from "react";

import ClaimEobAddControl from "../claim_eob_add_control";

const ClaimBottomMenu = (props) => {
  if (props.adjNewStatus === 2 || props.adjNewStatus === 70) {
    return (
      <div>
        <ClaimEobAddControl
          claimId={props.claimId}
          benefitSchemeItems={props.benefitSchemeItems}
          addEobItemLocally={props.addEobItemLocally}
          paymentOptions={props.paymentOptions}
          remarkOptions={props.remarkOptions}
          addEobPaymentItemLocally={props.addEobPaymentItemLocally}
          benefitLimitsAggregated={props.benefitLimitsAggregated}
          relatedEobItemsData={props.relatedEobItemsData}
          adjEobItems={props.adjEobItems}
          cpfHospitalListOptions={props.cpfHospitalListOptions}
          adjPaymentItems={props.adjPaymentItems}
          addEobRemarksLocally={props.addEobRemarksLocally}
          adjAdditionalRemarks={props.adjAdditionalRemarks}
          schoolPaymentOptions={props.schoolPaymentOptions}
          getBankDetailsOfPaymentItem={props.getBankDetailsOfPaymentItem}
          bankDetailsOfPaymentItem={props.bankDetailsOfPaymentItem}
          validatePaymentItemBankAccount={props.validatePaymentItemBankAccount}
          dataValidationPaymentItemBankAccount={props.dataValidationPaymentItemBankAccount}
        />
      </div>
    );
  }
  return null;
};

export default ClaimBottomMenu;
