import axios from "axios";
import { func } from "prop-types";
import moment from "moment";

const ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
// const ROOT_URL = 'http://localhost:8081';
// const ROOT_URL = 'http://68.183.184.9:8081';

// (1) Version
export const GET_VERSIONS = "GET_VERSIONS";
// (2) User Functions
export const DO_LOGIN_SUCCESS = "DO_LOGIN_SUCCESS";
export const DO_LOGIN_ERROR = "DO_LOGIN_ERROR";
export const DO_OTP_LOGIN_SUCCESS = "DO_OTP_LOGIN_SUCCESS";
export const DO_OTP_LOGIN_ERROR = "DO_OTP_LOGIN_ERROR";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_ERROR = "SEND_OTP_ERROR";

export const USER_LOGOUT = "USER_LOGOUT";
// (3) Adjudication Functions
export const GET_ADJ_CLAIMS_SUCCESS = "GET_ADJ_CLAIMS_SUCCESS";
export const GET_ADJ_CLAIMS_ZONE_SUMMARY_SUCCESS = "GET_ADJ_CLAIMS_ZONE_SUMMARY_SUCCESS";
export const GET_ADJ_CLAIMS_ERROR = "GET_ADJ_CLAIMS_ERROR";
export const GET_ADJ_CLAIM_SUCCESS = "GET_ADJ_CLAIM_SUCCESS";
export const GET_ADJ_CLAIM_ERROR = "GET_ADJ_CLAIM_ERROR";
export const GET_ADJ_CASE_SUCCESS = "GET_ADJ_CASE_SUCCESS";
export const GET_ADJ_CASE_ERROR = "GET_ADJ_CASE_ERROR";
export const ADD_EOB_ITEM_LOCALLY = "ADD_EOB_ITEM_LOCALLY";
export const REMOVE_EOB_ITEM_LOCALLY = "REMOVE_EOB_ITEM_LOCALLY";
export const ADD_EOB_PAYMENT_ITEM_LOCALLY = "ADD_EOB_PAYMENT_ITEM_LOCALLY";
export const REMOVE_EOB_PAYMENT_ITEM_LOCALLY = "REMOVE_EOB_PAYMENT_ITEM_LOCALLY";
export const SET_NEW_ADJ_CLAIM_STATUS = "SET_NEW_ADJ_CLAIM_STATUS";
export const ADD_EOB_REMARKS_LOCALLY = "ADD_EOB_REMARKS_LOCALLY";
export const SUBMIT_ADJ_RESULTS_SUCCESS = "SUBMIT_ADJ_RESULTS_SUCCESS";
export const SUBMIT_ADJ_RESULTS_ERROR = "SUBMIT_ADJ_RESULTS_ERROR";
export const RESET_LOCAL_ADJ_DATA = "RESET_LOCAL_ADJ_DATA";
export const SUBMIT_INJURY_TYPE_SUCCESS = "SUBMIT_INJURY_TYPE_SUCCESS";
export const SUBMIT_INJURY_TYPE_ERROR = "SUBMIT_INJURY_TYPE_ERROR";

export const GET_HOSPITAL_BANK_DETAILS_SUCCESS = "GET_HOSPITAL_BANK_DETAILS_SUCCESS";
export const GET_HOSPITAL_BANK_DETAILS_FAILED = "GET_HOSPITAL_BANK_DETAILS_FAILED";

export const GET_ALL_INSURER_BANK_DETAILS_SUCCESS = "GET_ALL_INSURER_BANK_DETAILS_SUCCESS";
export const GET_ALL_INSURER_BANK_DETAILS_FAILED = "GET_ALL_INSURER_BANK_DETAILS_FAILED";

export const SET_STATE_BANK_DETAILS_OF_PAYMENT_ITEM = "SET_STATE_BANK_DETAILS_OF_PAYMENT_ITEM";

export const VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_LOADING = "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_LOADING";
export const VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_SUCCESS = "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_SUCCESS";
export const VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED = "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED";
// (4) Config Functions
export const GET_CFG_BENEFIT_ITEMS_SUCCESS = "GET_CFG_BENEFIT_ITEMS_SUCCESS";
export const GET_CFG_BENEFIT_ITEMS_ERROR = "GET_CFG_BENEFIT_ITEMS_ERROR";
export const GET_CFG_BENEFIT_SCHEME_SUCCESS = "GET_CFG_BENEFIT_SCHEME_SUCCESS";
export const GET_CFG_BENEFIT_SCHEME_ERROR = "GET_CFG_BENEFIT_SCHEME_ERROR";
export const GET_CFG_PAYMENT_OPTIONS_SUCCESS = "GET_CFG_PAYMENT_OPTIONS_SUCCESS";
export const GET_CFG_PAYMENT_OPTIONS_ERROR = "GET_CFG_PAYMENT_OPTIONS_ERROR";
export const GET_CFG_REMARK_OPTIONS_SUCCESS = "GET_CFG_REMARK_OPTIONS_SUCCESS";
export const GET_CFG_REMARK_OPTIONS_ERROR = "GET_CFG_REMARK_OPTIONS_ERROR";
export const GET_CFG_STATUS_REMARK_OPTIONS_SUCCESS = "GET_CFG_STATUS_REMARK_OPTIONS_SUCCESS";
export const GET_CFG_STATUS_REMARK_OPTIONS_ERROR = "GET_CFG_STATUS_REMARK_OPTIONS_ERROR";
export const GET_CFG_CPF_HOSPITAL_LIST_SUCCESS = "GET_CFG_CPF_HOSPITAL_LIST_SUCCESS";
export const GET_CFG_CPF_HOSPITAL_LIST_ERROR = "GET_CFG_CPF_HOSPITAL_LIST_ERROR";
export const GET_CFG_SCHOOL_PAYMENT_OPTIONS_SUCCESS = "GET_CFG_SCHOOL_PAYMENT_OPTIONS_SUCCESS";
export const GET_CFG_SCHOOL_PAYMENT_OPTIONS_ERROR = "GET_CFG_SCHOOL_PAYMENT_OPTIONS_ERROR";
// (5) Records/Database Functions
export const GET_RECORD_SEARCH_RESULT_SUCCESS = "GET_RECORD_SEARCH_RESULT_SUCCESS";
export const GET_RECORD_SEARCH_RESULT_ERROR = "GET_RECORD_SEARCH_RESULT_ERROR";
export const GET_RECORD_CASE_DETAILS_SUCCESS = "GET_RECORD_CASE_DETAILS_SUCCESS";
export const GET_RECORD_CASE_DETAILS_ERROR = "GET_RECORD_CASE_DETAILS_ERROR";
export const GET_RECORD_CLAIM_DETAILS_SUCCESS = "GET_RECORD_CLAIM_DETAILS_SUCCESS";
export const GET_RECORD_CLAIM_DETAILS_ERROR = "GET_RECORD_CLAIM_DETAILS_ERROR";
export const SUBMIT_RECORD_CLAIM_CHANGE_CASEID_SUCCESS = "SUBMIT_RECORD_CLAIM_CHANGE_CASEID_SUCCESS";
export const SUBMIT_RECORD_CLAIM_CHANGE_CASEID_ERROR = "SUBMIT_RECORD_CLAIM_CHANGE_CASEID_ERROR";
export const SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_SUCCESS = "SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_SUCCESS";
export const SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_ERROR = "SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_ERROR";
export const SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_SUCCESS = "SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_SUCCESS";
export const SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_ERROR = "SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_ERROR";
export const REGENERATE_EOB_DATA_SUCCESS = "REGENERATE_EOB_DATA_SUCCESS";
export const REGENERATE_EOB_DATA_ERROR = "REGENERATE_EOB_DATA_ERROR";
export const REGENERATE_PAYMENT_FILES_SUCCESS = "REGENERATE_PAYMENT_FILES_SUCCESS";
export const REGENERATE_PAYMENT_FILES_ERROR = "REGENERATE_PAYMENT_FILES_ERROR";
export const GENERATE_DBS_PAYMENT_FILES_SUCCESS = "GENERATE_DBS_PAYMENT_FILES_SUCCESS";
export const GENERATE_DBS_PAYMENT_FILES_ERROR = "GENERATE_DBS_PAYMENT_FILES_ERROR";

// (6) Batch Functions
export const GET_BATCH_ALL_MISSION_EMAIL_CASE_SUCCESS = "GET_BATCH_ALL_MISSION_EMAIL_CASE_SUCCESS";
export const GET_BATCH_ALL_MISSION_EMAIL_CASE_ERROR = "GET_BATCH_ALL_MISSION_EMAIL_CASE_ERROR";
export const GET_BATCH_ALL_LIST_SUCCESS = "GET_BATCH_ALL_LIST_SUCCESS";
export const GET_BATCH_ALL_LIST_ERROR = "GET_BATCH_ALL_LIST_ERROR";
export const GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_SUCCESS =
   "GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_SUCCESS";
export const GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_ERROR = "GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_ERROR";
export const GET_BATCH_DETAIL_SUCCESS = "GET_BATCH_DETAIL_SUCCESS";
export const GET_BATCH_DETAIL_ERROR = "GET_BATCH_DETAIL_ERROR";
export const SUBMIT_MHC_REVIEW_BATCH_RESULT_SUCCESS = "SUBMIT_MHC_REVIEW_BATCH_RESULT_SUCCESS";
export const SUBMIT_MHC_REVIEW_BATCH_RESULT_ERROR = "SUBMIT_MHC_REVIEW_BATCH_RESULT_ERROR";
export const SUBMIT_INCOME_REVIEW_BATCH_RESULT_SUCCESS = "SUBMIT_INCOME_REVIEW_BATCH_RESULT_SUCCESS";
export const SUBMIT_INCOME_REVIEW_BATCH_RESULT_ERROR = "SUBMIT_INCOME_REVIEW_BATCH_RESULT_ERROR";
export const SUBMIT_PENDING_REJECTED_RESULT_SUCCESS = "SUBMIT_PENDING_REJECTED_RESULT_SUCCESS";
export const SUBMIT_PENDING_REJECTED_RESULT_ERROR = "SUBMIT_PENDING_REJECTED_RESULT_ERROR";
export const SUBMIT_PAID_CLAIMS_RESULT_SUCCESS = "SUBMIT_PAID_CLAIMS_RESULT_SUCCESS";
export const SUBMIT_PAID_CLAIMS_RESULT_ERROR = "SUBMIT_PAID_CLAIMS_RESULT_ERROR";
// (7) User Adminstration Functions
export const SUBMIT_NEW_USER_SUCCESS = "SUBMIT_NEW_USER_SUCCESS";
export const SUBMIT_NEW_USER_ERROR = "SUBMIT_NEW_USER_ERROR";
export const SUBMIT_GET_USER_LIST_SUCCESS = "SUBMIT_GET_USER_LIST_SUCCESS";
export const SUBMIT_GET_USER_LIST_ERROR = "SUBMIT_GET_USER_LIST_ERROR";
// (8) Report Functions
export const SUBMIT_REPORT_TYPE_ONE_SUCCESS = "SUBMIT_REPORT_TYPE_ONE_SUCCESS";
export const SUBMIT_REPORT_TYPE_ONE_ERROR = "SUBMIT_REPORT_TYPE_ONE_ERROR";
export const SUBMIT_REPORT_TYPE_TWO_SUCCESS = "SUBMIT_REPORT_TYPE_TWO_SUCCESS";
export const SUBMIT_REPORT_TYPE_TWO_ERROR = "SUBMIT_REPORT_TYPE_TWO_ERROR";
export const SUBMIT_REPORT_TYPE_THREE_SUCCESS = "SUBMIT_REPORT_TYPE_TWO_SUCCESS";
export const SUBMIT_REPORT_TYPE_THREE_ERROR = "SUBMIT_REPORT_TYPE_THREE_ERROR";
export const RESET_ADJUDICATION_LIST = "RESET_ADJUDICATION_LIST";

// (9) Open File Function
export const OPEN_ATTACHED_FILE = "OPEN_ATTACHED_FILE";
export const OPEN_ATTACHED_FILE_V2_SUCCESS = "OPEN_ATTACHED_FILE_V2_SUCCESS";
export const OPEN_ATTACHED_FILE_V2_FAILURE = "OPEN_ATTACHED_FILE_V2_FAILURE";
export const ATTACHMENT_MS_GET_FILE_URL_SUCCESS = "ATTACHMENT_MS_GET_FILE_URL_SUCCESS";
export const ATTACHMENT_MS_GET_FILE_URL_FAILURE = "ATTACHMENT_MS_GET_FILE_URL_FAILURE";
export const UPDATE_STATE_ADJ_CLAIM_SUPPORT_FILE_URL = "UPDATE_STATE_ADJ_CLAIM_SUPPORT_FILE_URL";
export const UPDATE_STATE_ADJ_CLAIM_RECEIPT_FILE_URL = "UPDATE_STATE_ADJ_CLAIM_RECEIPT_FILE_URL";


// (10) Check claim (claims_status = 80)
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_CHECK_CLAIMS_SUCCESS = "GET_CHECK_CLAIMS_SUCCESS";
export const GET_CHECK_CLAIMS_ERROR = "GET_CHECK_CLAIMS_ERROR";
export const GET_TOTAL_CHECK_CLAIMS_SUCCESS = "GET_TOTAL_CHECK_CLAIMS_SUCCESS";
export const GET_TOTAL_CHECK_CLAIMS_ERROR = "GET_TOTAL_CHECK_CLAIMS_ERROR";
export const SUBMIT_CHECK_CLAIMS_SUCCESS = "SUBMIT_CHECK_CLAIMS_SUCCESS";
export const SUBMIT_CHECK_CLAIMS_ERROR = "SUBMIT_CHECK_CLAIMS_ERROR";

// Add a response interceptor to axios to return the errorMessage from API instead of error object
axios.interceptors.response.use(
   function(response) {
      return response;
   },
   function(error) {
      if (error.response) {
         return Promise.reject(error.response.data || { errorMessage: "Something went wrong." });
      } else {
         return Promise.reject(error);
      }
   }
);

export function getEobFilesList(data, callback) {
   const url = `${ROOT_URL}/open/file`;

   return axios
      .post(url, { claimList: data }, { headers: { "Content-Type": "application/json" } })
      .then(response => {
         callback(response, null);
         return {
            type: OPEN_ATTACHED_FILE,
            payload: response
         };
      })
      .catch(error => {
         // console.log("error ---> ", error);
      });
}

export function sendOTP({ email, password }, callback) {
   // const encryptedPassword = encryptStringWithPublicKey(password);
   const url = `${ROOT_URL}/sendOTP`;
   return axios
      .post(url, {
         email,
         password
      })
      .then(response => {
         callback(response, null);
         return {
            type: SEND_OTP_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         callback(null, error);
         return {
            type: SEND_OTP_ERROR,
            payload: error
         };
      });
}

export function doOTPLogin({ id, otp }, callback) {
   // const encryptedPassword = encryptStringWithPublicKey(password);
   const url = `${ROOT_URL}/doLoginOTP`;
   return axios
      .post(url, {
         id,
         otp
      })
      .then(response => {
         callback(response.status, null);
         return {
            type: DO_OTP_LOGIN_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         callback(null, error);
         return {
            type: DO_OTP_LOGIN_ERROR,
            payload: error
         };
      });
}

export function doLogin({ email, password }, callback) {
   // const encryptedPassword = encryptStringWithPublicKey(password);
   const url = `${ROOT_URL}/doLogin`;
   return axios
      .post(url, {
         email,
         password
      })
      .then(response => {
         callback(response.status, null);
         return {
            type: DO_LOGIN_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         callback(null, error);
         return {
            type: DO_LOGIN_ERROR,
            payload: error
         };
      });
}

export function doLogout() {
   return {
      type: "USER_LOGOUT",
      payload: null
   };
}

export function getAjudicateClaimsZoneSummary({ accessToken }, callback) {
   const url = `${ROOT_URL}/adjudicate/claims/zone/summary`;
   return axios
      .get(url)
      .then(response => {
         // console.log("getAjudicateClaimsZoneSummary", response);
         return {
            type: GET_ADJ_CLAIMS_ZONE_SUMMARY_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_ADJ_CLAIMS_ERROR,
            payload: error
         };
      });
}

export function getSchools(callback) {
   const url = `${ROOT_URL}/schools`;

   return axios
      .get(url)
      .then(response => {
         // console.log("getSchools: ", response);
         return {
            type: GET_SCHOOLS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
      });
}

export function getCountries(callback) {
   const url = `https://8gpu2xeyl7.execute-api.ap-southeast-1.amazonaws.com/api/v1/get-countries`;
   return fetch(url, {
      method: "GET",
      headers: {
         "Content-Type": "application/x-www-form-urlencoded",
         Authorization: '{"access_name": "MHC_eLoG_API","access_token": "43d1MrgFAwZPQWK9F2h1"}'
      }
   })
      .then(response => response.json())
      .then(response => {
         // console.log("getCountries: ", response);
         return {
            type: GET_COUNTRIES_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
      });
}

export function submitCheckClaim({ caseId, claimId, values }, callback) {
   const url = `${ROOT_URL}/claims/checknew/validate`;
   return axios
      .post(url, { caseId, claimId, values })
      .then(response => {
         // console.log("submitCheckClaim", response);
         return {
            type: SUBMIT_CHECK_CLAIMS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: SUBMIT_CHECK_CLAIMS_ERROR,
            payload: error
         };
      });
}

export function getTotalCheckClaims({ accessToken, claimType }, callback) {
   const url = `${ROOT_URL}/claims/checknew`;
   return axios
      .post(url, { claimType })
      .then(response => {
         // console.log("getTotalCheckClaims", response);
         return {
            type: GET_TOTAL_CHECK_CLAIMS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_TOTAL_CHECK_CLAIMS_ERROR,
            payload: error
         };
      });
}

export function getCheckClaims({ accessToken, claimType }, callback) {
   // console.log("getCheckClaims: ", claimType);

   const url = `${ROOT_URL}/claims/checknew`;
   return axios
      .post(url, { claimType })
      .then(response => {
         // console.log("getCheckClaims", response);
         return {
            type: GET_CHECK_CLAIMS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_CHECK_CLAIMS_ERROR,
            payload: error
         };
      });
}

// export function removePaymentEobItemLocally(itemUuid, claimId) {
//    return {
//       type: REMOVE_EOB_PAYMENT_ITEM_LOCALLY,
//       payload: { itemUuid, claimId }
//    };
// }

export function resetAdjudicationList() {
   return {
      type: RESET_ADJUDICATION_LIST,
      payload: true
   };
}

export function getAjudicateClaims({ accessToken, greenZone, orangeZone, amberZone, redZone, offSet }, callback) {
   const url = `${ROOT_URL}/adjudicate/claims`;
   return axios
      .post(url, {
         greenZone,
         orangeZone,
         amberZone,
         redZone,
         offSet
      })
      .then(response => {
         // console.log("getAjudicateClaims", response);
         return {
            type: GET_ADJ_CLAIMS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_ADJ_CLAIMS_ERROR,
            payload: error
         };
      });
}

export function submitInjuryType({ accessToken, injuryType, caseId }, callback) {
   // console.log("dd: ", injuryType, caseId);
   const url = `${ROOT_URL}/case/injurytype`;
   return axios
      .post(
         url,
         {
            injuryType,
            caseId
         },
         {
            headers: { Accesstoken: accessToken }
         }
      )
      .then(response => {
         // console.log("getAjudicateClaims", response);
         return {
            type: SUBMIT_INJURY_TYPE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: SUBMIT_INJURY_TYPE_ERROR,
            payload: error
         };
      });
}

export function getAjudicateClaim({ accessToken, claimId }, callback) {
   // console.log('getAjudicateClaim', claimId);
   const url = `${ROOT_URL}/claim/${claimId}`;
   return axios
      .get(url)
      .then(response => {
         // console.log("getAjudicateClaim: ", response);
         return {
            type: GET_ADJ_CLAIM_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_ADJ_CLAIM_ERROR,
            payload: error
         };
      });
}

export function getAdjudicateCase({ accessToken, caseId }, callback) {
   const url = `${ROOT_URL}/case/${caseId}`;
   return axios
      .get(url)
      .then(response => {
         // console.log("getAdjudicateCase", response);
         return {
            type: GET_ADJ_CASE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log(error);
         return {
            type: GET_ADJ_CASE_ERROR,
            payload: error
         };
      });
}

export function getBenefitSchemeItems({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/benefitSchemeItems`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBenefitSchemeItems", response);
         return {
            type: GET_CFG_BENEFIT_ITEMS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBenefitSchemeItems", error);
         return {
            type: GET_CFG_BENEFIT_ITEMS_ERROR,
            payload: error
         };
      });
}

export function getEobPaymentOptions({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/eobPaymentOptions`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getEobPaymentOptions", response);
         return {
            type: GET_CFG_PAYMENT_OPTIONS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getEobPaymentOptions", error);
         return {
            type: GET_CFG_PAYMENT_OPTIONS_ERROR,
            payload: error
         };
      });
}

export function getEobRemarkOptions({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/eobRemarkOptions`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getEobRemarkOptions", response);
         return {
            type: GET_CFG_REMARK_OPTIONS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getEobRemarkOptions", error);
         return {
            type: GET_CFG_REMARK_OPTIONS_ERROR,
            payload: error
         };
      });
}

export function getSchoolPaymentOptions({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/eobSchoolPaymentOptions`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getSchoolPaymentOptions", response);
         return {
            type: GET_CFG_SCHOOL_PAYMENT_OPTIONS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getEobRemarkOptions", error);
         return {
            type: GET_CFG_SCHOOL_PAYMENT_OPTIONS_ERROR,
            payload: error
         };
      });
}

export function addEobItemLocally(item, claimId) {
   return {
      type: ADD_EOB_ITEM_LOCALLY,
      payload: { item, claimId }
   };
}

export function removeEobItemLocally(itemUuid, claimId) {
   return {
      type: REMOVE_EOB_ITEM_LOCALLY,
      payload: { itemUuid, claimId }
   };
}

export function setNewAjClaimStatus(status, claimId) {
   return {
      type: SET_NEW_ADJ_CLAIM_STATUS,
      payload: { status, claimId }
   };
}

export function addEobPaymentItemLocally(item, claimId) {
   return {
      type: ADD_EOB_PAYMENT_ITEM_LOCALLY,
      payload: { item, claimId }
   };
}

export function addEobRemarksLocally(remarks, claimId) {
   return {
      type: ADD_EOB_REMARKS_LOCALLY,
      payload: { remarks, claimId }
   };
}

export function removePaymentEobItemLocally(itemUuid, claimId) {
   return {
      type: REMOVE_EOB_PAYMENT_ITEM_LOCALLY,
      payload: { itemUuid, claimId }
   };
}

export function resetLocalAdjudicationData(claimId) {
   return {
      type: RESET_LOCAL_ADJ_DATA,
      payload: claimId
   };
}

export function submitAdjudicationResults({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/adjudicate/results/${data.caseId}/${data.claimId}`;
   return axios
      .post(url, data, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("submitAdjudicationResults", response);
         callback(response, null);
         return {
            type: SUBMIT_ADJ_RESULTS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitAdjudicationResults", error);
         callback(null, error);
         return {
            type: SUBMIT_ADJ_RESULTS_ERROR,
            payload: error
         };
      });
}

export function getRecordSearchResults({ data, accessToken }, callback) {
   const url = `${ROOT_URL}/records/search?type=${data.searchType}&text=${data.searchText}`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getRecordSearchResults", response);
         callback(response, null);
         return {
            type: GET_RECORD_SEARCH_RESULT_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getRecordSearchResults", error);
         callback(null, error);
         return {
            type: GET_RECORD_SEARCH_RESULT_ERROR,
            payload: error
         };
      });
}

export function getRecordCaseDetails(data, callback) {
   const url = `${ROOT_URL}/case/${data.caseId}`;
   return axios
      .get(url)
      .then(response => {
         // console.log("getRecordCaseDetails", response);
         // callback(response, null);
         return {
            type: GET_RECORD_CASE_DETAILS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getRecordCaseDetails", error);
         // callback(null, error);
         return {
            type: GET_RECORD_CASE_DETAILS_ERROR,
            payload: error
         };
      });
}

export function getRecordClaimDetails(data, callback) {
   const url = `${ROOT_URL}/claim/${data.claimId}`;
   return axios
      .get(url)
      .then(response => {
         // console.log("getRecordClaimDetails", response);
         // callback(response, null);
         return {
            type: GET_RECORD_CLAIM_DETAILS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getRecordClaimDetails", error);
         // callback(null, error);
         return {
            type: GET_RECORD_CLAIM_DETAILS_ERROR,
            payload: error
         };
      });
}

export function getStatusRemarkOptions({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/adjStatusRemarkOptions`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getStatusRemarkOptions", response);
         return {
            type: GET_CFG_STATUS_REMARK_OPTIONS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getStatusRemarkOptions", error);
         return {
            type: GET_CFG_STATUS_REMARK_OPTIONS_ERROR,
            payload: error
         };
      });
}

export function getBenefitScheme({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/benefitScheme`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBenefitScheme", response);
         return {
            type: GET_CFG_BENEFIT_SCHEME_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBenefitScheme", error);
         return {
            type: GET_CFG_BENEFIT_SCHEME_ERROR,
            payload: error
         };
      });
}

export function submitRecordClaimChangeCaseId({ data, accessToken }, callback) {
   // console.log(data, accessToken);
   const url = `${ROOT_URL}/records/claimChangeCaseID`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitRecordClaimChangeCaseId", response);
         callback(response, null);
         return {
            type: SUBMIT_RECORD_CLAIM_CHANGE_CASEID_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitRecordClaimChangeCaseId", error);
         callback(null, error);
         return {
            type: SUBMIT_RECORD_CLAIM_CHANGE_CASEID_ERROR,
            payload: error
         };
      });
}

export function getBatchAllMissingEmailCase(data, accessToken, callback) {
   const url = `${ROOT_URL}/batches/cases`;

   return axios
      .post(url, data, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBatchAllMissingEmailCase", response);
         return {
            type: GET_BATCH_ALL_MISSION_EMAIL_CASE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBatchAllMissingEmailCase", error);
         return {
            type: GET_BATCH_ALL_MISSION_EMAIL_CASE_ERROR,
            payload: error
         };
      });
}

export function getBatchAllList(accessToken, callback) {
   const url = `${ROOT_URL}/batches`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBatchAllList", response);
         return {
            type: GET_BATCH_ALL_LIST_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBatchAllList", error);
         return {
            type: GET_BATCH_ALL_LIST_ERROR,
            payload: error
         };
      });
}

export function getBatchPendingRejectClaimsToProcessList(accessToken, callback) {
   const url = `${ROOT_URL}/batches/pendingAndRejected`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBatchPendingRejectClaimsToProcessList", response);
         return {
            type: GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBatchPendingRejectClaimsToProcessList", error);
         return {
            type: GET_BATCH_PENDING_REJECTED_CLAIMS_TO_PROCESS_ERROR,
            payload: error
         };
      });
}

export function getSearchBatchList({ data, accessToken }, callback) {
   // console.log(accessToken);
   const url = `${ROOT_URL}/batches/search/${data.searchType}/${data.searchValue}`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getSearchBatchList", response);
         callback(response, null);
         return {
            type: GET_BATCH_ALL_LIST_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getSearchBatchList", error);
         callback(null, error);
         return {
            type: GET_BATCH_ALL_LIST_ERROR,
            payload: error
         };
      });
}

export function getBatchDetail({ accessToken, batchId }, callback) {
   const url = `${ROOT_URL}/batch/${batchId}`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getBatchDetail", response);
         return {
            type: GET_BATCH_DETAIL_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getBatchDetail", error);
         return {
            type: GET_BATCH_DETAIL_ERROR,
            payload: error
         };
      });
}

export function submitMhcReviewBatchResults({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/batch/update/mhcResults`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitMhcReviewBatchResults", response);
         callback(response, null);
         return {
            type: SUBMIT_MHC_REVIEW_BATCH_RESULT_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitMhcReviewBatchResults", error);
         callback(null, error);
         return {
            type: SUBMIT_MHC_REVIEW_BATCH_RESULT_ERROR,
            payload: error
         };
      });
}

export function submitIncomeReviewBatchResults({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/batch/update/incomeResults`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitIncomeReviewBatchResults", response);
         callback(response, null);
         return {
            type: SUBMIT_INCOME_REVIEW_BATCH_RESULT_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitIncomeReviewBatchResults", error);
         callback(null, error);
         return {
            type: SUBMIT_INCOME_REVIEW_BATCH_RESULT_ERROR,
            payload: error
         };
      });
}

export function submitPendingRejectedResults({ updateBatchClaimsData, missingEmailCaseData, accessToken }, callback) {
   const url = `${ROOT_URL}/batches/pendingAndRejectedResults`;
   // console.log("missingEmailData: ", missingEmailCaseData);
   return axios
      .post(url, { updateBatchClaimsData, missingEmailCaseData }, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitPendingRejectedResults", response);
         callback(response, null);
         return {
            type: SUBMIT_PENDING_REJECTED_RESULT_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitPendingRejectedResults", error);
         callback(null, error);
         return {
            type: SUBMIT_PENDING_REJECTED_RESULT_ERROR,
            payload: error
         };
      });
}

export function submitPaidClaimsResults({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/batch/update/paymentResults`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitPaidClaimsResults", response);
         callback(response, null);
         return {
            type: SUBMIT_PAID_CLAIMS_RESULT_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitPaidClaimsResults", error);
         callback(null, error);
         return {
            type: SUBMIT_PAID_CLAIMS_RESULT_ERROR,
            payload: error
         };
      });
}

export function submitCreateNewUser({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/user/create`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitCreateNewUser", response);
         callback(response, null);
         return {
            type: SUBMIT_NEW_USER_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitCreateNewUser", error);
         callback(null, error);
         return {
            type: SUBMIT_NEW_USER_ERROR,
            payload: error
         };
      });
}

export function getAdminUserList(accessToken, callback) {
   const url = `${ROOT_URL}/users/list`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getAdminUserList", response);
         return {
            type: SUBMIT_GET_USER_LIST_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getAdminUserList", error);
         return {
            type: SUBMIT_GET_USER_LIST_ERROR,
            payload: error
         };
      });
}

export function getCfgCpfHospitalListOptions({ accessToken }, callback) {
   const url = `${ROOT_URL}/config/cpfHospitalListOptions`;
   return axios
      .get(url, {
         headers: { Accesstoken: accessToken }
      })
      .then(response => {
         // console.log("getCfgCpfHospitalListOptions", response);
         return {
            type: GET_CFG_CPF_HOSPITAL_LIST_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("getCfgCpfHospitalListOptions", error);
         return {
            type: GET_CFG_CPF_HOSPITAL_LIST_ERROR,
            payload: error
         };
      });
}

export function submitGetReportOneData({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/reports/typeone`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitGetReportOneData", response);
         callback(response, null);
         return {
            type: SUBMIT_REPORT_TYPE_ONE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitGetReportOneData", error);
         callback(null, error);
         return {
            type: SUBMIT_REPORT_TYPE_ONE_ERROR,
            payload: error
         };
      });
}

export function submitGetReportTwoData({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/reports/typetwo`;
   return axios
      .post(url, data, { responseType: "blob" }, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitGetReportTwoData", response);
         callback(response, null);

         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, "Billing-Report.xlsx");
            return;
         }

         let url = URL.createObjectURL(new Blob([response.data]));
         const anchor = document.createElement("a");
         anchor.href = url;
         anchor.download = "Billing-Report.xlsx";
         document.body.appendChild(anchor);
         anchor.click();
         window.URL.revokeObjectURL(url);

         return {
            type: SUBMIT_REPORT_TYPE_ONE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitGetReportTwoData", error);
         callback(null, error);
         return {
            type: SUBMIT_REPORT_TYPE_ONE_ERROR,
            payload: error
         };
      });
}

export function submitGetReportThreeData({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/reports/typethree`;
   return axios
      .post(url, data, { responseType: "blob" }, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitGetReportThreeData", response);
         callback(response, null);

         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(response, "KPI-Report.xlsx");
            return;
         }

         let url = URL.createObjectURL(new Blob([response.data]));
         const anchor = document.createElement("a");
         anchor.href = url;
         anchor.download = "KPI Report " + moment(data.fromDate).format("DD-MMM-YYYY") + " to " + moment(data.toDate).format("DD-MMM-YYYY") + ".xlsx";
         document.body.appendChild(anchor);
         anchor.click();
         window.URL.revokeObjectURL(url);

         return {
            type: SUBMIT_REPORT_TYPE_THREE_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitGetReportTwoData", error);
         callback(null, error);
         return {
            type: SUBMIT_REPORT_TYPE_THREE_ERROR,
            payload: error
         };
      });
}

export function submitRecordClaimUpdatePaidStatus({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/claims/setStatusToPaid`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitRecordClaimUpdatePaidStatus", response);
         callback(response, null);
         return {
            type: SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitRecordClaimUpdatePaidStatus", error);
         callback(null, error);
         return {
            type: SUBMIT_RECORD_CLAIM_UPDATE_PAID_STATUS_ERROR,
            payload: error
         };
      });
}

export function submitRecordClaimResetAdjData({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/claims/resetAdjudicationData`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("submitRecordClaimResetAdjData", response);
         callback(response, null);
         return {
            type: SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("submitRecordClaimResetAdjData", error);
         callback(null, error);
         return {
            type: SUBMIT_RECORD_CLAIM_RESET_ADJ_DATA_ERROR,
            payload: error
         };
      });
}

export function regenerateEOBData({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/claims/regenerateEOBData`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("regenerateEOBData", response);
         callback(response, null);
         return {
            type: REGENERATE_EOB_DATA_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("regenerateEOBData", error);
         callback(null, error);
         return {
            type: REGENERATE_EOB_DATA_ERROR,
            payload: error
         };
      });
}

export function regeneratePaymentFiles({ accessToken, data }, callback) {
   const url = `${ROOT_URL}/batch/update/regeneratePaymentFiles`;
   return axios
      .post(url, data, { headers: { Accesstoken: accessToken } })
      .then(response => {
         // console.log("regeneratePaymentFiles", response);
         callback(response, null);
         return {
            type: REGENERATE_PAYMENT_FILES_SUCCESS,
            payload: response
         };
      })
      .catch(error => {
         // console.log("regeneratePaymentFiles", error);
         callback(null, error);
         return {
            type: REGENERATE_PAYMENT_FILES_ERROR,
            payload: error
         };
      });
}

export function generateDBSPaymentFile({ accessToken, data }, callback) {
   // console.log("generateDBSPaymentFile: data", data);
   const url = `${ROOT_URL}/batch/update/generateDBSPaymentFile`;
   return axios
   .post(url, data, { headers: { Accesstoken: accessToken } })
   .then(response => {
      // console.log("generateDBSPaymentFile: response", response);
      callback(response, null);
      return {
         type: GENERATE_DBS_PAYMENT_FILES_SUCCESS,
         payload: response
      };
   })
   .catch(error => {
      // console.log("generateDBSPaymentFile", error);
      callback(null, error);
      return {
         type: GENERATE_DBS_PAYMENT_FILES_ERROR,
         payload: error
      };
   });
}

export function getEobFilesListV2(claimList, callback) {
   const url = `${ROOT_URL}/open/file-v2`;

   return axios
       .post(url, { claimList }, { headers: { "Content-Type": "application/json" } })
       .then(response => {
          if (callback) {
             callback(response, null);
          }
          return {
             type: OPEN_ATTACHED_FILE_V2_SUCCESS,
             payload: response
          };
       })
       .catch(error => {
          let errMsg = error.message || "Error getFileUrl"
          if (error && error.data && (error.data.error || error.data.message)) {
             errMsg = error.data.error || error.data.message;
          }

          if (callback) {
             callback(null, errMsg);
          }

          return {
             type: OPEN_ATTACHED_FILE_V2_FAILURE,
             error: errMsg
          };
       });
}

export function attachmentMsGetFileUrl({ accessToken, attachmentToken }, callback) {
   const url = `${ROOT_URL}/attachment-ms/get-file-url/${attachmentToken}`;

   return axios
       .get(url, {
          headers: { Accesstoken: accessToken }
       })
       .then(response => {
          let fileUrl = response && response.data && response.data.fileUrl ? response.data.fileUrl : "";
          if (!fileUrl) {
             console.log("attachmentMsGetFileUrl response : ", response);
          }

          if (callback) {
             callback(null, fileUrl);
          }
          return {
             type: ATTACHMENT_MS_GET_FILE_URL_SUCCESS,
             payload: fileUrl
          };
       })
       .catch(error => {
          let errMsg = error.message || "Error attachmentMsGetFileUrl"
          if (error && error.data && (error.data.error || error.data.message)) {
             errMsg = error.data.error || error.data.message;
          }

          if (callback) {
             callback(errMsg, null);
          }

          return {
             type: ATTACHMENT_MS_GET_FILE_URL_FAILURE,
             error: errMsg
          };
       });
}

export function updateStateAdjClaimReceiptFileUrl(claimId, receiptArrayIndex, loadingFileUrl, fileUrl) {
   return {
      type: UPDATE_STATE_ADJ_CLAIM_RECEIPT_FILE_URL,
      payload: { claimId, idx: receiptArrayIndex, loadingFileUrl, fileUrl }
   };
}

export function updateStateAdjClaimSupportFileUrl(claimId, supportDocumentArrayIndex, loadingFileUrl, fileUrl) {
   return {
      type: UPDATE_STATE_ADJ_CLAIM_SUPPORT_FILE_URL,
      payload: { claimId, idx: supportDocumentArrayIndex, loadingFileUrl, fileUrl }
   };
}

export function getBankDetailsByHospitalCode({ accessToken, hospital_code }, callback) {
   return axios
       .post(
           `${ROOT_URL}/bank/hospital-bank-details`,
           { hospital_code },
           { headers: { Accesstoken: accessToken } }
       )
       .then(response => {
          if (callback) {
             callback(null, response.data.result);
          }

          return {
             type: GET_HOSPITAL_BANK_DETAILS_SUCCESS,
             payload: response.data.result
          };
       })
       .catch(error => {
          console.log("error getBankDetailsByHospitalCode ==> ", error)
          let err = error && error.response && error.response.data ? error.response.data : error;
          let errMsg = err.message || "Failed to get the bank details of the hospital"

          if (callback) {
             callback(errMsg, null);
          }

          return {
             type: GET_HOSPITAL_BANK_DETAILS_FAILED,
             error: errMsg
          };
       })
}

export function getAllInsurerBankDetails({ accessToken }, callback) {
   return axios
       .get(
           `${ROOT_URL}/bank/all-insurer-bank-details`,
           { headers: { Accesstoken: accessToken } }
       )
       .then(response => {
          if (callback) {
             callback(null, response.data.results);
          }

          return {
             type: GET_ALL_INSURER_BANK_DETAILS_SUCCESS,
             payload: response.data.results
          };
       })
       .catch(error => {
          console.log("error getAllInsurerBankDetails ==> ", error)
          let err = error && error.response && error.response.data ? error.response.data : error;
          let errMsg = err.message || "Failed to get all insurer bank details"

          if (callback) {
             callback(errMsg, null);
          }

          return {
             type: GET_ALL_INSURER_BANK_DETAILS_FAILED,
             error: errMsg
          };
       })
}

export function setStateBankDetailsOfPaymentItem(data) {
   return {
      type: SET_STATE_BANK_DETAILS_OF_PAYMENT_ITEM,
      loading: data.loading || false,
      payload: data.payload || null,
      error: data.error || ""
   };
}

export function setLoadingValidationPaymentItemBankAccount() {
   return { type: VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_LOADING };
}

export function validationPaymentItemBankAccount({ accessToken, bankCode, accountNumber, branchCode }, callback) {
   return axios
       .post(
           `${ROOT_URL}/bank/validate-account`,
           {
              bank_code: bankCode,
              account_number: accountNumber,
              branch_code: branchCode
           },
           { headers: { Accesstoken: accessToken } }
       )
       .then(response => {
          if (callback) {
             callback(null, response.data);
          }

          const dispatchObj = {
             type: "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED",
             isValid: false,
             message: "",
             payload: null
          };

          const responseData = response && response.data ? response.data : null;

          if (responseData && responseData.success) {
             dispatchObj.type = "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_SUCCESS";
             dispatchObj.isValid = true;

             const responseDataResult = responseData && responseData.result ? responseData.result : null;

             if (responseDataResult) {
                let bankDetails = {
                   bank_code: "",
                   bank_account_number: "",
                   bank_branch: ""
                };

                if (responseDataResult.bankNo !== undefined && responseDataResult.bankNo !== null) {
                   bankDetails.bank_code = responseDataResult.bankNo;
                }

                if (responseDataResult.accountNo !== undefined && responseDataResult.accountNo !== null) {
                   bankDetails.bank_account_number = responseDataResult.accountNo;
                }

                if (responseDataResult.branchCode !== undefined && responseDataResult.branchCode !== null) {
                   bankDetails.bank_branch = responseDataResult.branchCode;
                }

                dispatchObj.payload = bankDetails;
             }
          }

          if (responseData && responseData.message) {
             dispatchObj.message = responseData.message;
          }


          return dispatchObj;
       })
       .catch(error => {
          console.log("error validateHospitalBankAccount ==> ", error)
          let err = error && error.response && error.response.data ? error.response.data : error;
          let errMsg = err.message || err.errorMessage || "Failed to validate bank account"

          if (callback) {
             callback(errMsg, null);
          }

          return {
             type: "VALIDATE_PAYMENT_ITEM_BANK_ACCOUNT_FAILED",
             isValid: false,
             message: errMsg,
             payload: null
          };
       })
}