import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Grid, Loader, Modal, Segment} from "semantic-ui-react";

import {
  addEobItemLocally,
  addEobPaymentItemLocally,
  addEobRemarksLocally,
  attachmentMsGetFileUrl,
  getAdjudicateCase,
  getAjudicateClaim,
  getBenefitSchemeItems,
  removeEobItemLocally,
  removePaymentEobItemLocally,
  resetLocalAdjudicationData,
  setNewAjClaimStatus,
  submitAdjudicationResults,
  submitInjuryType,
  updateStateAdjClaimReceiptFileUrl,
  updateStateAdjClaimSupportFileUrl,
  getBankDetailsByHospitalCode,
  getAllInsurerBankDetails,
  setStateBankDetailsOfPaymentItem,
  setLoadingValidationPaymentItemBankAccount,
  validationPaymentItemBankAccount
} from "../../actions";
import ClaimCaseDataDisplay from "../../component/adjudicate/claims/claim_case_data_display";
// import ClaimEobItemsControl from '../../component/adjudicate/claims/claim_eob_items_control';
import ClaimStatusUpdateControl from "../../component/adjudicate/claims/claim_status_update_control";
import ClaimBottomMenu from "../../component/adjudicate/claims/claim_bottom_menu";
import ClaimResultMessageModal from "../../component/adjudicate/claims/claim_result_message_modal";
import ClaimStatusActionControl from "../../component/adjudicate/claims/claim_status_action_control";
import ClaimImageDisplay from "../../component/adjudicate/claims/claim_image_display";
import FormsInjuryType from "../../component/forms/case/injury_type";
import moment from "moment";
import _ from "lodash";

class AdjudicateClaimPage extends Component {
  state = {
    isSubmitting: false,
    isSubmitted: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
    triggerModal: false,
    triggerModalMessage: "",
    triggerModalType: 0,
    triggerInjuryModal: false,
    resultData: [],
  };
  componentDidMount() {
    const { caseId, claimId } = this.props.match.params;
    // console.log('componentDidMount', caseId);
    const { accessToken } = this.props.user;

    if (!this.props.claimData[claimId]) {
      this.props.getAdjudicateCase({ caseId }).then((result) => {
        if (this.props.caseData[caseId]) {
          this.props.getAjudicateClaim({ claimId }).then((result) => {
            if (this.props.claimData[claimId]) {
              this.validateReceiptDateIncurred(caseId, claimId);
              this.generateFileURLs(claimId);
            } else {
              this.setState({
                isError: true,
                errorMessage: result.payload.errorMessage,
              });
            }
          });
        } else {
          this.setState({
            isError: true,
            errorMessage: result.payload.errorMessage,
          });
        }
      });
    } else if (this.props.caseData[caseId] && !this.props.adjClaimsData[claimId]) {
      this.props.getAjudicateClaim({ claimId }).then((result) => {
        if (this.props.claimData[claimId]) {
          this.validateReceiptDateIncurred(caseId, claimId);
          this.generateFileURLs(claimId);
        } else {
          this.setState({
            isError: true,
            errorMessage: result.payload.errorMessage,
          });
        }
      });
    } else if (this.props.claimData[claimId]) {
      this.generateFileURLs(claimId);
    }

    // if (!this.props.claimData || this.props.claimData.claim_id !== claimId) {
    //     console.log('fetching data...');
    //     this.props.getAdjudicateCase({caseId});
    //     this.props.getAjudicateClaim({claimId});
    // }
    this.props.getBenefitSchemeItems({ accessToken });
  }
  validateReceiptDateIncurred(caseId, claimId) {
    const accidentDate = this.props.caseData[caseId]
      ? this.props.caseData[caseId].date_of_accident
      : null;
    const receipts = this.props.claimData[claimId]
      ? this.props.claimData[claimId].receipts
      : null;

    if (receipts && receipts.length) {
      receipts.forEach((receipt) => {
        if (moment(receipt.date_incurred) < moment(accidentDate)) {
          this.setState({
            triggerModal: true,
            triggerModalType: 1,
            triggerModalMessage:
              "Receipt date incurred is before accident date.",
          });
        }
      });
    }
  }
  submitAdjudicationResults = (additionalData) => {
    this.setState({
      isSubmitting: true,
      isSubmitted: false,
      isSuccess: false,
      errorMessage: null,
    });
    const {
      adjNewStatus,
      adjEobItems,
      adjEobIncurredAmount,
      adjEobPayableAmount,
      adjEobPaymentAmount,
      adjPaymentItems,
      adjAdditionalRemarks,
    } = this.props.adjClaimsData[this.props.match.params.claimId];
    let resultData = {
      caseId: this.props.match.params.caseId,
      claimId: this.props.match.params.claimId,
      newStatus: adjNewStatus,
      adjEobItems: adjEobItems,
      adjEobIncurredAmount: adjEobIncurredAmount,
      adjEobPaymentAmount: adjEobPaymentAmount,
      adjEobPayableAmount: adjEobPayableAmount,
      adjPaymentItems: adjPaymentItems,
      adjAdditionalRemarks: adjAdditionalRemarks,
      ...additionalData,
    };

    this.setState({ resultData }, () => {
      let message = "Invalid payment item in the list.";

      const filterItemWithEmptyPaymentItemId = (item) => {
        return item.paymentItemId === "";
      };
      let paymentItemsValidate =
        adjPaymentItems.filter(filterItemWithEmptyPaymentItemId).length === 0;
      let benefitHospitalAllowanceValidate = true;
      let benefitItemsValidate = true;

      if (!paymentItemsValidate) {
        this.setState({
          isSubmitting: false,
          triggerModal: true,
          triggerModalType: 1,
          triggerModalMessage: message,
        });

        return;
      }

      const duplicateBenefitCheck = adjEobItems.reduce((res, obj) => {
        const groupBy = `${obj.benefitId}-${
          obj.incurredAmount
        }-${obj.invoiceNo.trim()}-${moment(obj.fromDate).format(
          "DD-MM-YYYY"
        )}-${moment(obj.toDate).format("DD-MM-YYYY")}`;

        res[groupBy] = {
          count: groupBy,
        };
        return res;
      }, []);

      const duplicatePaymentCheck = adjPaymentItems.reduce((res, obj) => {
        let payeeName = obj.schemeData ? obj.schemeData.description : "";

        if (payeeName === "SCHOOL") {
          payeeName = obj.schoolPaymentName;
        }

        if (payeeName === "OTHERS") {
          payeeName = obj.otherPaymentName;
        }

        const groupBy = `${obj.paymentItemId}-${obj.amount}-${payeeName}`;

        res[groupBy] = {
          count: groupBy,
        };
        return res;
      }, []);

      if (Object.keys(duplicateBenefitCheck).length === adjEobItems.length) {
        benefitItemsValidate = true;
      } else {
        benefitItemsValidate = false;
        message =
          "Unable to proceed. Duplicate benefit item found in the list.";
        this.setState({
          isSubmitting: false,
          triggerModal: true,
          triggerModalType: 1,
          triggerModalMessage: message,
        });

        return;
      }

      if (
        Object.keys(duplicatePaymentCheck).length === adjPaymentItems.length
      ) {
        paymentItemsValidate = true;
      } else {
        paymentItemsValidate = false;
        message =
          "Unable to proceed. Duplicate payment item found in the list.";
        this.setState({
          isSubmitting: false,
          triggerModal: true,
          triggerModalType: 1,
          triggerModalMessage: message,
        });

        return;
      }

      // Validate if benefitId(13) - Daily Room and Board, then must include benefitId(16) - Hospital Allowance in the list.
      if (
        adjEobItems.find(
          (benefit) => benefit.benefitId === 13 || benefit.benefitId === "13"
        )
      ) {
        if (
          adjEobItems.find(
            (benefit) => benefit.benefitId === 16 || benefit.benefitId === "16"
          )
        ) {
          benefitHospitalAllowanceValidate = true;
        } else {
          benefitHospitalAllowanceValidate = false;
          message =
            "You have not added a Hospital Allowance benefit when there is a Room and Board, do you still want to proceed?";
          this.setState({
            isSubmitting: false,
            triggerModal: true,
            triggerModalType: 2,
            triggerModalMessage: message,
          });

          return;
        }
      }

      this.actionCallSubmitAdjudicationResults();
    });
  };

  submitResultWithoutHospitalAllowance = () => {
    this.setState({ triggerModal: false, isSubmitting: true }, () => {
      this.actionCallSubmitAdjudicationResults();
    });
  };

  actionCallSubmitAdjudicationResults = () => {
    const { accessToken } = this.props.user;
    this.props.submitAdjudicationResults(
      { accessToken, data: this.state.resultData },
      (result, error) => {
        if (error) {
          // console.log(error);
          this.setState({
            isSubmitting: false,
            isSubmitted: true,
            isSuccess: false,
            errorMessage: error.errorMessage,
          });
        }
        if (result) {
          this.setState({
            isSubmitting: false,
            isSubmitted: true,
            isSuccess: true,
          });
          // reset adjudication data in redux
          this.props.resetLocalAdjudicationData(
            this.props.match.params.claimId
          );
        }
      }
    );
  };
  validateClaimStatusUpdate = (updatedClaimStatus) => {
    // Validate if the updated claim status is Approved(Proceed to Batch).
    if (updatedClaimStatus === 2 || updatedClaimStatus === "2") {
      const { caseId } = this.props.match.params;
      const targetCaseData = this.props.caseData[caseId];

      if (_.isEmpty(targetCaseData.injury_type)) {
        this.setState({ triggerInjuryModal: true });
      }
    }
  };
  submitInjuryType(injuryType) {
    const { accessToken } = this.props.user;
    const { caseId } = this.props.match.params;
    this.props
      .submitInjuryType({ accessToken, injuryType, caseId })
      .then(() => {
        this.props.getAdjudicateCase({ caseId }).then(() => {
          this.setState({ triggerInjuryModal: false });
        });
      });
  }

  // new attachment
  async generateFileURLs(claimId) {
    const { accessToken } = this.props.user;

    // receipts set loading state
    if (this.props.claimData[claimId].receipts) {
      for (let i = 0; i < this.props.claimData[claimId].receipts.length; i++) {
        let attachmentToken = this.props.claimData[claimId].receipts[i].attachment_token;
        if (attachmentToken) {
          this.props.updateStateAdjClaimReceiptFileUrl(claimId, i, true, "");
        }
      }
    }

    // supportDocuments set loading state
    if (this.props.claimData[claimId].supportDocuments) {
      for (let i = 0; i < this.props.claimData[claimId].supportDocuments.length; i++) {
        let attachmentToken = this.props.claimData[claimId].supportDocuments[i].attachment_token;
        if (attachmentToken) {
          this.props.updateStateAdjClaimSupportFileUrl(claimId, i, true, "");
        }
      }
    }

    const delayEachRequest = 1000;
    // receipt get file URL
    if (this.props.claimData[claimId].receipts) {
      for (let i = 0; i < this.props.claimData[claimId].receipts.length; i++) {
        let attachmentToken = this.props.claimData[claimId].receipts[i].attachment_token;
        if (attachmentToken) {
          await new Promise(async resolve => {
            await setTimeout(async () => {
              await this.props.attachmentMsGetFileUrl({accessToken, attachmentToken}, async (error, response) => {
                let fileUrl = ""

                if (response) {
                  fileUrl = response;
                }

                await new Promise(resolve2 => {
                  this.props.updateStateAdjClaimReceiptFileUrl(claimId, i, false, fileUrl);
                  resolve2();
                })
                resolve();
              })
            }, delayEachRequest)
          })
        }
      }
    }

    // supportDocuments get file URL
    if (this.props.claimData[claimId].supportDocuments) {
      for (let i = 0; i < this.props.claimData[claimId].supportDocuments.length; i++) {
        let attachmentToken = this.props.claimData[claimId].supportDocuments[i].attachment_token;
        if (attachmentToken) {
          await new Promise(async resolve => {
            await setTimeout(async () => {
              await this.props.attachmentMsGetFileUrl({accessToken, attachmentToken}, async (error, response) => {
                let fileUrl = ""

                if (response) {
                  fileUrl = response;
                }

                await new Promise(resolve2 => {
                  this.props.updateStateAdjClaimSupportFileUrl(claimId, i, false, fileUrl);
                  resolve2();
                })
                resolve();
              })
            }, delayEachRequest)
          })
        }
      }
    }

  }

  getBankDetailsOfPaymentItem = async (objParam) => {
    const { accessToken } = this.props.user;
    const type = objParam.type;

    await this.props.setStateBankDetailsOfPaymentItem({
      loading: true,
      payload: null,
      error: ""
    });

    const stateBankDetails = {
      loading: false,
      payload: null,
      error: ""
    };

    if (type && typeof type === "string" && type.trim().toUpperCase() === "HOSPITAL") {
      let bankDetails = await this.props.getBankDetailsByHospitalCode({ accessToken, hospital_code: objParam.code });
      if (bankDetails.payload) {
        stateBankDetails.payload = {
          bank_code: bankDetails.payload.bankCode || "",
          bank_account_number: bankDetails.payload.bankAccount || "",
          bank_branch: bankDetails.payload.bankBranch || "",
        }
      }

      if (bankDetails.error) {
        stateBankDetails.error = bankDetails.error;
      }
    }

    if (type && typeof type === "string" && type.trim().toUpperCase() === "INSURER") {
      let bankDetails = await this.props.getAllInsurerBankDetails({ accessToken });
      if (bankDetails.payload && Array.isArray(bankDetails.payload) && bankDetails.payload.length > 0) {
        let filteredBankDetails = bankDetails.payload.filter(el => {
          const insurerCodeFilter = objParam.code && typeof objParam.code === "string" ? objParam.code.trim().toLowerCase() : "";
          return el &&
              el.insurer_code &&
              typeof el.insurer_code === "string" &&
              el.insurer_code.trim() !== "" &&
              el.insurer_code.trim().toLowerCase() === insurerCodeFilter;
        });

        if (filteredBankDetails && filteredBankDetails.length > 0 && filteredBankDetails[0]) {
          stateBankDetails.payload = {
            bank_code: filteredBankDetails[0].bank_code || "",
            bank_account_number: filteredBankDetails[0].account_number || "",
            bank_branch: filteredBankDetails[0].branch_code || "",
          }
        }
      }

      if (bankDetails.error) {
        stateBankDetails.error = bankDetails.error;
      }
    }

    await this.props.setStateBankDetailsOfPaymentItem(stateBankDetails);
  }

  validatePaymentItemBankAccount = async (bankDetails) => {
    const { accessToken } = this.props.user;
    await this.props.setLoadingValidationPaymentItemBankAccount();
    await this.props.validationPaymentItemBankAccount({
      accessToken,
      bankCode: bankDetails.bankCode,
      accountNumber: bankDetails.accountNumber,
      branchCode: bankDetails.branchCode
    });
  }

  render() {
    let { caseId, claimId } = this.props.match.params;
    if (this.state.isSubmitted === true && this.state.isSuccess === true) {
      return (
        <ClaimResultMessageModal show={true} history={this.props.history} />
      );
    }
    if (this.state.isError) {
      return <p className="p-10">Error: {this.state.errorMessage}</p>;
    }
    if (!this.props.caseData[caseId]) {
      let newCaseId = claimId.split('M');
      caseId = newCaseId[0];
    }
    // console.log('match params', this.props.match.params)
    // console.log('case data by case id', this.props.caseData[caseId])
    // console.log('case data by claim id', this.props.caseData[claimId])
    // problem is here in adj claim data
    // console.log('adj claim data by claim id', this.props.adjClaimsData[claimId])
    if (
      !this.state.isError &&
      (!this.props.caseData[caseId] ||
        !this.props.claimData[claimId] ||
        !this.props.benefitSchemeItems)
    ) {
      return <Loader active />;
    }
    // console.log(this.props.caseData);
    // console.log('hello--->', this.props.adjClaimsData);
    if (!this.state.isError && !this.props.adjClaimsData[claimId]) {
      // console.log('waiting to load adjClaimsData')
      return <Loader active />;
    }
    const {
      adjNewStatus,
      adjEobItems,
      adjEobIncurredAmount,
      adjEobPayableAmount,
      adjEobPaymentAmount,
      adjPaymentItems,
      adjAdditionalRemarks,
    } = this.props.adjClaimsData[claimId];
    // console.log('adjNewStatus---->', this.props.adjClaimsData[claimId].adjNewStatus)
    return (
      <div>
        <div>
          <Modal open={this.state.triggerInjuryModal}>
            <Modal.Header>Missing Injury Type</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>
                  There is no Injury Type for this case. Claim needs to have an
                  injury type before you can approve this claim. Please choose
                  an Injury Type here:
                </p>
                <FormsInjuryType
                  submitInjuryType={this.submitInjuryType.bind(this)}
                  cancelInjuryModal={() => {
                    this.setState({ triggerInjuryModal: false });
                  }}
                />
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <Modal open={this.state.triggerModal}>
            <Modal.Header>Warning</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>{this.state.triggerModalMessage}</p>
                {this.state.triggerModalType === 1 ? (
                  <Button
                    onClick={() => {
                      this.setState({ triggerModal: false });
                    }}
                  >
                    Close
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button onClick={this.submitResultWithoutHospitalAllowance}>
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({ triggerModal: false });
                      }}
                    >
                      No
                    </Button>
                  </React.Fragment>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>

        <Grid columns={3}>
          <Grid.Row stretched style={{marginBottom: adjNewStatus === 2 || adjNewStatus === 70 ? '20%' : ''}}>
            <Grid.Column style={{backgroundColor: 'rgb(187, 187, 187)'}}>
              <ClaimCaseDataDisplay
                caseData={this.props.caseData[caseId]}
                claimData={this.props.claimData[claimId]}
              />
            </Grid.Column>

            <Grid.Column>
              <Segment>
                <ClaimImageDisplay
                  supportDocuments={
                    this.props.claimData[claimId].supportDocuments
                  }
                  receipts={this.props.claimData[claimId].receipts}
                />
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment style={{backgroundColor: 'rgb(187, 187, 187)'}}>
                <ClaimStatusUpdateControl
                  validateClaimStatusUpdate={this.validateClaimStatusUpdate}
                  setNewAjClaimStatus={this.props.setNewAjClaimStatus}
                  adjNewStatus={adjNewStatus}
                  claimId={claimId}
                />
                <ClaimStatusActionControl
                  claimId={claimId}
                  adjNewStatus={adjNewStatus}
                  adjEobItems={adjEobItems}
                  removeEobItemLocally={this.props.removeEobItemLocally}
                  removePaymentEobItemLocally={
                    this.props.removePaymentEobItemLocally
                  }
                  adjEobIncurredAmount={adjEobIncurredAmount}
                  adjEobPaymentAmount={adjEobPaymentAmount}
                  adjEobPayableAmount={adjEobPayableAmount}
                  adjPaymentItems={adjPaymentItems}
                  submitAdjudicationResults={this.submitAdjudicationResults}
                  isSubmitting={this.state.isSubmitting}
                  statusRemarkOptions={this.props.statusRemarkOptions}
                  adjError={this.props.adjError}
                  adjErrorObject={this.props.adjErrorObject}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <div style={{position: 'fixed', bottom: 0, width: '100%'}}>
          <ClaimBottomMenu
            claimId={claimId}
            adjNewStatus={adjNewStatus}
            benefitSchemeItems={this.props.benefitSchemeItems}
            addEobItemLocally={this.props.addEobItemLocally}
            paymentOptions={this.props.paymentOptions}
            remarkOptions={this.props.remarkOptions}
            addEobPaymentItemLocally={this.props.addEobPaymentItemLocally}
            benefitLimitsAggregated={
              this.props.caseData[caseId].benefitLimitsAggregated
            }
            adjEobItems={adjEobItems}
            relatedEobItemsData={
              this.props.caseData[caseId].relatedEobItemsData
            }
            cpfHospitalListOptions={this.props.cpfHospitalListOptions}
            adjPaymentItems={adjPaymentItems}
            addEobRemarksLocally={this.props.addEobRemarksLocally}
            adjAdditionalRemarks={adjAdditionalRemarks}
            schoolPaymentOptions={this.props.schoolPaymentOptions}
            getBankDetailsOfPaymentItem={this.getBankDetailsOfPaymentItem.bind(this)}
            bankDetailsOfPaymentItem={this.props.bankDetailsOfPaymentItem}
            validatePaymentItemBankAccount={this.validatePaymentItemBankAccount.bind(this)}
            dataValidationPaymentItemBankAccount={this.props.dataValidationPaymentItemBankAccount}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    claimData: state.adjudicate.adjClaim,
    caseData: state.adjudicate.adjCase,
    adjEobItems: state.adjudicate.adjEobItems,
    adjPaymentItems: state.adjudicate.adjPaymentItems,
    adjEobIncurredAmount: state.adjudicate.adjEobIncurredAmount,
    adjEobPaymentAmount: state.adjudicate.adjEobPaymentAmount,
    adjEobPayableAmount: state.adjudicate.adjEobPayableAmount,
    adjAdditionalRemarks: state.adjudicate.adjAdditionalRemarks,
    adjNewStatus: state.adjudicate.adjNewStatus,
    benefitSchemeItems: state.config.benefitSchemeItems,
    paymentOptions: state.config.paymentOptions,
    remarkOptions: state.config.remarkOptions,
    statusRemarkOptions: state.config.statusRemarkOptions,
    cpfHospitalListOptions: state.config.cpfHospitalListOptions,
    schoolPaymentOptions: state.config.schoolPaymentOptions,
    adjClaimsData: state.adjudicate.adjClaimsData,
    adjError: state.adjudicate.error,
    adjErrorObject: state.adjudicate.errorObject,
    bankDetailsOfPaymentItem: state.adjudicate.bankDetailsOfPaymentItem,
    dataValidationPaymentItemBankAccount: state.adjudicate.dataValidationPaymentItemBankAccount
  };
};

export default connect(mapStateToProps, {
  getAdjudicateCase,
  getAjudicateClaim,
  getBenefitSchemeItems,
  addEobItemLocally,
  removeEobItemLocally,
  setNewAjClaimStatus,
  addEobPaymentItemLocally,
  removePaymentEobItemLocally,
  submitAdjudicationResults,
  resetLocalAdjudicationData,
  addEobRemarksLocally,
  submitInjuryType,
  attachmentMsGetFileUrl,
  updateStateAdjClaimSupportFileUrl,
  updateStateAdjClaimReceiptFileUrl,
  getBankDetailsByHospitalCode,
  getAllInsurerBankDetails,
  setStateBankDetailsOfPaymentItem,
  setLoadingValidationPaymentItemBankAccount,
  validationPaymentItemBankAccount
})(AdjudicateClaimPage);

/*
<div style={{position: 'fixed', width: 430, backgroundColor: '#AAAAAA', zIndex: 995, top: 50, bottom: 0, right: 0, padding: 0, marginBottom: 120, overflowY: 'scroll', overflowX: 'hidden'}}>
*/
